@charset "UTF-8";

html {
  height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
section {
  flex: 1;
}


h1{
    border-bottom: 2px solid #2ea7e0;
}

.icon {
    font-size: 50px;
    text-align: center;
    color: rgb(46, 167, 224);
    display: inline-block;
    justify-content: center;
    margin-bottom: 10px;
}

a {
    color: inherit;
}

header {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
}

.headA {
    font-size: 16px;
    box-shadow: 2px 2px 4px rgb(176, 176, 177);
    background: linear-gradient(white, #e0ffff);
}

#headB {
    font-size: 20px;
    a {
        display: block;
    }
}

.topt {
    font-size: 50px;
    margin-top: 30px;
}

.point {
    border-bottom: solid 2px #2ea7e0;
    font-size: 22px;
    background-color: #fff;
}

.pointbox {
    background-color: rgba(234, 238, 241, 0.5);
    ul{
        li{
            padding: 5px;
        }
    }
}

.linked {
    position: relative;
    z-index: 1;
        a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: -999px;
        z-index: 2;
        &:hover {
            background-color: rgba(46, 167, 224, 0.2);
            }
    }
}

.toplink{
    border-bottom: 1px solid rgba(87, 82, 84,0.7);
}

footer {
    background-color: #2ea7e0;
}
ol{
    line-height: 2;
}

@media(min-width: 768px) {
    .headC {
        display: none;
    }
}

@media(max-width: 767px) {
    #headB {
        display: none;
    }
    header .container-s {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headC {
            margin-right: 10px;
            padding: 0;
            border: none;
            outline: none;
            background: none;
            font-size: 28px;
            opacity: 0.5;
            cursor: pointer;
        }
    }
}

strong[id] {
    padding-top: 125px;
    margin-top:-125px;
  }